
import { Vue } from 'vue-class-component';

let _id: number = 0;

class Disk {
  _size: number
  id: number

  constructor(size: number) {
    this._size = size;
    this.id = _id++;
  }

  get size(): number {
    return this._size;
  }

  set size(value: number) {
    this._size = Math.abs(value);
  }
}


class RAIDFunction {
  name: string;
  stable: boolean
  usage: number[] | null;

  constructor(name: string, stable: boolean, usage: number[] | null) {
    this.name = name;
    this.stable = stable;
    this.usage = usage;
  }
}


export default class DiskCalculator extends Vue {
  disks: Disk[] = [];

  addDisk(size: number = 4) {
    this.disks.push(new Disk(size));
  }

  removeDisk(id: number) {
    for (let i = 0; i < this.disks.length; i++) {
      if (this.disks[i].id == id) {
        this.disks.splice(i, 1);
        return;
      }
    }
  }

  getTotalSize(): number {
    let total = 0
    for (let disk of this.disks) {
      if (typeof disk.size == "number") {
        total += disk.size;
      }
    }
    return total
  }

  getNumDisks(): number {
    let count = 0
    for (let disk of this.disks) {
      if (typeof disk.size == "number") {
        count++;
      }
    }
    return count
  }

  diskSizes(): number[] {
    // Get a sorted list of the disk sizes
    let sizes = [];
    for (let d of this.disks) {
      if (typeof d.size == "number") {
        sizes.push(d.size);
      }
    }
    sizes.sort((a: number, b: number) => b - a)
    return sizes;
  }

  RAIDCalculation(extra_copies: number): number[] | null {
    if (this.getNumDisks() < extra_copies + 1) {
      return null;
    }
    // find the smallest disk, and stripe that same space on the disks with the most available size
    // https://www.spinics.net/lists/linux-btrfs/msg09832.html
    // https://btrfs.wiki.kernel.org/index.php/Manpage/mkfs.btrfs#PROFILES
    let disks = this.diskSizes();
    let usedSpace = 0;

    for (; ;) {
      usedSpace += disks[disks.length - 1];

      for (let i = 0; i < extra_copies; i++) {
        disks[i] -= disks[disks.length - 1];
      }
      disks.pop();

      disks.sort((a: number, b: number) => b - a)

      while (disks[disks.length - 1] === 0) {
        disks.pop();
      }
      if (disks.length < extra_copies + 1) {
        break;
      }
    }
    return [
      usedSpace,  // How much space is available for use
      usedSpace * extra_copies,  // How much space is used for copies of data
      this.getTotalSize() - usedSpace - (usedSpace * extra_copies)  // How much space was unusable
    ];
  }

  getRAIDLevels(): RAIDFunction[] {
    return [
      new RAIDFunction('Single', true, this.single),
      new RAIDFunction('DUP', true, this.dup),
      new RAIDFunction('RAID1', true, this.RAID1),
      new RAIDFunction('RAID1c3', true, this.RAID1c3),
      new RAIDFunction('RAID1c4', true, this.RAID1c4),
    ]
  }

  get single(): number[] | null {
    if (this.getNumDisks() < 1) {
      return null;
    }
    return [this.getTotalSize(), 0, 0];
  }

  get dup(): number[] | null {
    if (this.getNumDisks() < 1) {
      return null;
    }
    return [this.getTotalSize() / 2, this.getTotalSize() / 2, 0];
  }

  get RAID1(): number[] | null {
    return this.RAIDCalculation(1);
  }

  get RAID1c3(): number[] | null {
    return this.RAIDCalculation(2);
  }

  get RAID1c4(): number[] | null {
    return this.RAIDCalculation(3);
  }
}
